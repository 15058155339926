.App {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

@media(width: 1200px) {
  .App {
    display: flex;
    justify-content: flex-start;
  }
}
